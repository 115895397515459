import { useState, useCallback, useEffect, useRef } from 'react';
import L from 'leaflet';
import '../../../../../../../styles/NewDashboard/ShiftReview/ShiftCard/ShiftExpanded/EventDetail/MapView.css';
import 'leaflet/dist/leaflet.css';

const PlayIcon = () => (
  <svg width="15" height="15" viewBox="0 0 24 24" fill="currentColor">
    <path d="M8 5v14l11-7z" />
  </svg>
);

const PauseIcon = () => (
  <svg width="15" height="15" viewBox="0 0 24 24" fill="currentColor">
    <path d="M6 19h4V5H6v14zm8-14v14h4V5h-4z" />
  </svg>
);

const MapView = ({ locations = [], size = 'large', event }) => {
  const [map, setMap] = useState(null);
  const [currentTime, setCurrentTime] = useState(event ? new Date(event.timestamp) : null);
  const [isPlaying, setIsPlaying] = useState(false);
  const markerRef = useRef(null);
  // Remove the polylineRef

  const createCustomIcon = useCallback(() => {
    return L.divIcon({
      className: 'custom-icon',
      html: `<svg width="50" height="50" viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg">
               <circle cx="25" cy="25" r="20" fill="red" opacity="0.4"/>
             </svg>`,
      iconSize: [50, 50],
      iconAnchor: [25, 25],
      popupAnchor: [0, -25],
    });
  }, []);

  const displayMap = useCallback(
    (node) => {
      if (node !== null && map === null && locations.length > 0) {
        const newMap = L.map(node, {
          zoomControl: true,
          dragging: true,
          scrollWheelZoom: true,
          doubleClickZoom: true,
          attributionControl: false,
        });

        L.tileLayer(
          `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v11/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_PUBLIC_TOKEN}`,
          {
            attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
          }
        ).addTo(newMap);

        const bounds = L.latLngBounds(locations.map((loc) => [loc.latitude, loc.longitude]));

        markerRef.current = L.marker(bounds.getCenter(), {
          icon: createCustomIcon(),
        }).addTo(newMap);

        // Remove the polyline creation

        // Fit bounds with maximum zoom level
        newMap.fitBounds(bounds, {
          padding: [50, 50],
          maxZoom: 18, // Adjust this value if needed
        });

        // Ensure the map updates its size after rendering
        setTimeout(() => {
          newMap.invalidateSize();
          newMap.fitBounds(bounds, {
            padding: [50, 50],
            maxZoom: 18,
          });
        }, 100);

        setMap(newMap);
      }
    },
    [map, locations, createCustomIcon]
  );

  const getLocationAtTime = useCallback(
    (time) => {
      if (locations.length === 0) return null;

      // Convert time to milliseconds for easier comparison
      const targetTime = time.getTime();

      // Find the location with the closest time
      let closestLocation = locations[0];
      let smallestTimeDiff = Math.abs(new Date(locations[0].time).getTime() - targetTime);

      for (let i = 1; i < locations.length; i++) {
        const locationTime = new Date(locations[i].time).getTime();
        const timeDiff = Math.abs(locationTime - targetTime);

        if (timeDiff < smallestTimeDiff) {
          closestLocation = locations[i];
          smallestTimeDiff = timeDiff;
        }

        // If we've found an exact match or passed the target time, we can stop searching
        if (timeDiff === 0 || locationTime > targetTime) break;
      }

      return closestLocation;
    },
    [locations]
  );

  useEffect(() => {
    if (event) {
      setCurrentTime(new Date(event.timestamp));
      setIsPlaying(false);
    }
  }, [event]);

  useEffect(() => {
    if (map && locations.length > 0 && currentTime) {
      const currentLocation = getLocationAtTime(currentTime);
      if (currentLocation) {
        const currentZoom = map.getZoom();
        map.setView([currentLocation.latitude, currentLocation.longitude], currentZoom, {
          animate: true,
          duration: 0.5,
        });
        markerRef.current.setLatLng([currentLocation.latitude, currentLocation.longitude]);
      }
    }
  }, [map, locations, currentTime, getLocationAtTime]);

  useEffect(() => {
    if (locations.length === 0) return;
    const startTime = new Date(locations[0].time);
    const endTime = new Date(locations[locations.length - 1].time);
    let interval;
    if (isPlaying) {
      interval = setInterval(() => {
        setCurrentTime((prevTime) => {
          const newTime = new Date((prevTime || startTime).getTime() + 60000); // Add 1 minute
          if (newTime > endTime) {
            setIsPlaying(false); // Stop playing when reaching the end
            return endTime; // Set to end time
          }
          return newTime;
        });
      }, 40); // Update every 10ms for smoother animation
    }
    return () => clearInterval(interval);
  }, [isPlaying, locations]);

  const handleSliderChange = (event) => {
    if (locations.length === 0) return;
    const startTime = new Date(locations[0].time);
    const endTime = new Date(locations[locations.length - 1].time);
    const totalDuration = endTime - startTime;
    const progress = Number(event.target.value) / 100;
    const newTime = new Date(startTime.getTime() + totalDuration * progress);
    setCurrentTime(newTime);
    if (newTime >= endTime) {
      setIsPlaying(false);
    }
  };

  const togglePlayPause = () => {
    setIsPlaying(!isPlaying);
  };

  const formatTime = (date) => {
    const timeString = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: true });
    const [time, period] = timeString.split(' ');
    return (
      <div className="time-display">
        <span>{time}</span>
        <br />
        <span className="period">{period}</span>
      </div>
    );
  };

  if (locations.length === 0) {
    return <div className={`map-view-container ${size}`}>No locations available.</div>;
  }

  const startTime = new Date(locations[0].time);
  const endTime = new Date(locations[locations.length - 1].time);
  const totalDuration = endTime - startTime;
  const totalHours = Math.ceil(totalDuration / (1000 * 60 * 60));
  const timeMarkerCount = Math.min(13, totalHours + 1); // Limit to 13 markers maximum

  return (
    <div className={`map-view-container ${size}`}>
      {/* <div className="forecast-header">
        <p>{startTime.toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}</p>
      </div> */}
      <div style={{ height: size === 'large' ? '550px' : '250px' }} ref={displayMap} />
      {/* <div className="map-controls">
        <div className="controls-row">
          <button className="circular-button" onClick={togglePlayPause}>
            {isPlaying ? <PauseIcon /> : <PlayIcon />}
          </button>
          <div className="event-info">
            <p>
              {new Date(event.timestamp).toLocaleDateString([], {
                weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric',
              })}
            </p>
          </div>
        </div>
        <input
          type="range"
          min="0"
          max="100"
          value={(((currentTime || startTime) - startTime) / totalDuration) * 100}
          onChange={handleSliderChange}
        />
        <div className="time-markers">
          {Array.from({ length: timeMarkerCount }, (_, i) => {
            const time = new Date(startTime.getTime() + (i / (timeMarkerCount - 1)) * totalDuration);
            return <span key={i}>{formatTime(time)}</span>;
          })}
        </div>
      </div> */}
    </div>
  );
};

export default MapView;
